import { AxiosInstance } from "@bps/http-client";
import {
  BpIdUserDto,
  BpIdUserSignInLogArgs,
  BpIdUserSignInLog,
  BpIdAuditLogArgs,
  BpIdAuditLog,
  CloudApplicationsRefDataDto,
  InvitationsArgs,
  ResultsResponse,
  BpIdUsersSearchArgs,
  UserGroupDto,
  UserGroupSearch,
  UserInviteArgs,
  UserInviteDto,
  UserInviteResponse
} from "./bp-id.dtos";
import qs from "query-string";
import { withPaging } from "@libs/paging/paging.utils";
import { PagingTableStorageResponse } from "@libs/paging/paging-table-storage-response.type";
import { IBpIdOpsGateway } from "./BpIdOpsGateway.interface";
import { DateTime } from "@bps/utils";
import {
  MIN_PAGE_ITEMS,
  PAGING_PAGE_NUMBER,
  PAGING_TOTAL_COUNT,
  PAGING_TOTAL_PAGES
} from "@libs/api/types/headers.constants";
import { PagingResponse } from "@libs/paging/paging-response.type";

export class BpIdOpsGateway implements IBpIdOpsGateway {
  constructor(private api: AxiosInstance) {}

  public async getUserDetails(bpIdUserId: string): Promise<BpIdUserDto> {
    const { data } = await this.api.get(`/users/details/${bpIdUserId}`);
    return data;
  }

  public async getUserSignInLogs(
    args: BpIdUserSignInLogArgs
  ): Promise<
    PagingTableStorageResponse<BpIdUserSignInLog, BpIdUserSignInLogArgs>
  > {
    const url = qs.stringifyUrl({
      url: "users/signInLogs",
      query: {
        userId: args.userId,
        startDate: args?.startDate
          ? DateTime.fromJSDate(args?.startDate).toISO()
          : undefined,
        endDate: args?.endDate
          ? DateTime.fromJSDate(args?.endDate)
              .endOf("day")
              .toISO()
          : undefined,
        pageLength: MIN_PAGE_ITEMS,
        nextPageToken: args.nextPageToken
      }
    });

    const data = (await this.api.get(url)).data;
    return { results: data.list, args: data.args };
  }

  public async getAuditLogs(
    args: BpIdAuditLogArgs
  ): Promise<PagingTableStorageResponse<BpIdAuditLog, BpIdAuditLogArgs>> {
    const url = qs.stringifyUrl({
      url: "users/auditLogs",
      query: {
        startDate: args?.startDate
          ? DateTime.fromJSDate(args?.startDate).toISO()
          : undefined,
        endDate: args?.endDate
          ? DateTime.fromJSDate(args?.endDate)
              .endOf("day")
              .toISO()
          : undefined,
        pageLength: MIN_PAGE_ITEMS,
        nextPageToken: args.nextPageToken
      }
    });

    const data = (await this.api.get(url)).data;
    return { results: data.list, args: data.args };
  }

  public deleteUser = (bpIdUserId: string): Promise<void> => {
    return this.api.delete(`/users/${bpIdUserId}`);
  };

  public postIdentitySearch(
    values: BpIdUsersSearchArgs
  ): Promise<ResultsResponse<BpIdUserDto>> {
    return this.api
      .post<ResultsResponse<BpIdUserDto>>("/users/$search", values)
      .then(({ data }) => data);
  }

  public async postAudit(id: string, text: string): Promise<any> {
    return (await this.api.post("/audit", { Id: id, text })).data;
  }

  public async getGroup(groupId: string): Promise<UserGroupDto> {
    return (await this.api.get(`/groups/${groupId}`)).data;
  }

  public async getAllGroups(): Promise<UserGroupSearch> {
    return (await this.api.get("/groups/")).data;
  }

  public async getUserGroups(userId: string): Promise<UserGroupDto[]> {
    return (await this.api.get(`/users/${userId}/groups`)).data?.groups;
  }

  public async updateUserGroups(
    userId: string,
    addGroupIds: string[],
    removeGroupIds: string[]
  ): Promise<BpIdUserDto> {
    return (
      await this.api.put(`/users/${userId}/groups`, {
        userId,
        addGroupIds,
        removeGroupIds
      })
    ).data;
  }

  public async addUserToGroup(
    userId: string,
    groupId: string
  ): Promise<UserGroupDto> {
    return (await this.api.post(`/groups/${groupId}/members/${userId}`)).data;
  }

  public async removeUserFromGroup(
    userId: string,
    groupId: string
  ): Promise<UserGroupDto> {
    return (await this.api.delete(`/groups/${groupId}/members/${userId}`)).data;
  }

  public fetchInvitations = async (
    args?: InvitationsArgs
  ): Promise<PagingResponse<UserInviteDto>> => {
    const page = args?.pageParam ?? 1;

    const encodedName = btoa(args?.name ?? "");

    const url = qs.stringifyUrl({
      url: "invites",
      query: withPaging({
        tenantId: args?.tenantId,
        userId: args?.userId,
        encodedName,
        createdStartDate: args?.createdStartDate,
        createdEndDate: args?.createdEndDate,
        expiryStartDate: args?.expiryStartDate,
        expiryEndDate: args?.expiryEndDate,
        isExpired: args?.isExpired,
        isRedeemed: args?.isRedeemed,
        isCancelled: args?.isCancelled,
        sortField: args?.sortField,
        sortAscending: args?.sortAscending,
        page: args?.pageParam ?? 1,
        limit: args?.limit ?? MIN_PAGE_ITEMS
      })
    });

    const response = await this.api.get<UserInviteDto[]>(url);

    const totalPages = +response.headers[PAGING_TOTAL_PAGES];
    const pageNumber = +response.headers[PAGING_PAGE_NUMBER];

    const isMaxPages = pageNumber >= totalPages;

    const total = +response.headers[PAGING_TOTAL_COUNT];

    return {
      results: response.data,
      next: !isMaxPages ? page + 1 : undefined,
      total,
      totalPages
    };
  };

  public sendUserInvite = async (
    payload: UserInviteArgs
  ): Promise<UserInviteResponse> => {
    return (await this.api.post("/invites/$sendInvite", payload)).data;
  };

  public resendUserInvite = async (id: string): Promise<void> => {
    return (await this.api.post(`/invites/${id}/$reSendInvite`)).data;
  };

  public cancelUserInvite = async (id: string): Promise<void> => {
    return (await this.api.put(`/invites/${id}/$cancel`)).data;
  };

  public getCloudApplicationsRefData = (): Promise<
    CloudApplicationsRefDataDto[]
  > => {
    return this.api.get("ref/cloudApplications").then(({ data }) => data);
  };

  public logoutAllSessions = (bpIdUserId: string): Promise<boolean> => {
    return this.api
      .get(`/users/logoutAllSessions/${bpIdUserId}`)
      .then(({ data }) => data);
  };

  public userRemoveTenant = (
    tenantId: string,
    userId: string
  ): Promise<boolean> => {
    return this.api
      .put(`/users/remove/tenant/${tenantId}/${userId}`)
      .then(({ data }) => data);
  };
}
