import { useRootStore } from "@stores/StoresProvider";
import {
  cacheDeleteSingleItemInArray,
  cacheUpsertSingleItemInArray
} from "@libs/react-query/react-query-cache.utils";
import {
  BpIdUserDto,
  BpIdUserSignInLog,
  BpIdUserSignInLogArgs,
  BpIdAuditLog,
  BpIdAuditLogArgs,
  InvitationsArgs,
  ResultsResponse,
  BpIdUsersSearchArgs,
  UserGroupDto,
  UserGroupSearch,
  UserInviteArgs,
  UserInviteDto,
  UserInviteResponse,
  CloudApplicationsRefDataDto
} from "./bp-id.dtos";
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import { HttpError } from "@bps/http-client";
import { UseQueryOptions } from "react-query/types/react/types";
import { useGateways } from "@libs/api/gateways-context";
import { PagingTableStorageResponse } from "@libs/paging/paging-table-storage-response.type";
import { PagingResponse } from "@libs/paging/paging-response.type";
import { PltCacheKeys } from "../plt/plt-gateway.hooks";

export const BpIdCacheKeys = {
  BpIdentities: "bp-identities",
  BpIdSignInLogs: "bp-signinlogs",
  BpIdAuditLogs: "bp-auditlogs",
  UserGroup: "user-group",
  UserGroups: "user-groups",
  BusinessUserGroups: "business-user-groups",
  Invitations: "users-invitations",
  CloudApplicationsRefData: "cloud-applications-ref-data"
} as const;

export interface UserGroupMutationArgs {
  userId: string;
  groupId: string;
}

export interface UpdateUserGroupsMutationArgs {
  userId: string;
  addGroupIds: string[];
  removeGroupIds: string[];
}
const invalidateInvitationsQueries = (queryClient: QueryClient) => {
  const queries = queryClient.getQueryCache().getAll();
  const userSearchQuery = queries.filter(q =>
    q.queryKey.includes(BpIdCacheKeys.Invitations)
  );
  userSearchQuery.forEach(query => {
    queryClient.invalidateQueries(query);
  });
};

const invalidateBpIdentitiesQueries = (queryClient: QueryClient) => {
  const queries = queryClient.getQueryCache().getAll();
  const userSearchQuery = queries.filter(q =>
    q.queryKey.includes(BpIdCacheKeys.BpIdentities)
  );

  const invalidateQueries = userSearchQuery.map(query =>
    queryClient.invalidateQueries(query)
  );
  return Promise.all(invalidateQueries);
};

export const useDeleteUserMutation = () => {
  const { bpIdOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, HttpError, string>(bpIdOpsGateway.deleteUser, {
    onSuccess: async () => {
      await invalidateBpIdentitiesQueries(queryClient);
      feedback.success("User has been deleted!");
    }
  });
};
export const useBusinessUserSearch = (values?: BpIdUsersSearchArgs) => {
  const { bpIdOpsGateway } = useGateways();
  return useQuery<ResultsResponse<BpIdUserDto>>(
    [BpIdCacheKeys.BpIdentities, values],
    async () => {
      return values
        ? await bpIdOpsGateway.postIdentitySearch(values)
        : { records: [], tooManyResults: false };
    },
    {
      enabled: Boolean(values)
    }
  );
};

// TODO: Add methods here for other API operations

// Audit

export const useGetGroup = (groupId: string) => {
  const { bpIdOpsGateway } = useGateways();

  return useQuery<UserGroupDto | undefined>(
    [BpIdCacheKeys.UserGroup, { groupId }],
    async () => {
      return await bpIdOpsGateway.getGroup(groupId);
    }
  );
};

export const useGetAllGroups = () => {
  const { bpIdOpsGateway } = useGateways();

  return useQuery<UserGroupSearch | undefined>(
    [BpIdCacheKeys.UserGroups],
    async () => {
      return await bpIdOpsGateway.getAllGroups();
    }
  );
};

export const useGetUserGroups = (userId: string) => {
  const { bpIdOpsGateway } = useGateways();

  return useQuery<UserGroupDto[] | undefined>(
    [BpIdCacheKeys.BusinessUserGroups, userId],
    async () => {
      return await bpIdOpsGateway.getUserGroups(userId);
    }
  );
};

export const useUpdateUserGroupsMutation = () => {
  const { bpIdOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<BpIdUserDto, HttpError, UpdateUserGroupsMutationArgs>(
    ({ userId, addGroupIds, removeGroupIds }) =>
      bpIdOpsGateway.updateUserGroups(userId, addGroupIds, removeGroupIds),
    {
      onSuccess: async (_dto, args) => {
        await invalidateBpIdentitiesQueries(queryClient);
        await queryClient.invalidateQueries([
          BpIdCacheKeys.BusinessUserGroups,
          args.userId
        ]);
        feedback.success("User groups updated!");
      },
      onError: error => {
        feedback.error(error);
      }
    }
  );
};

export const useAddUserToGroupMutation = () => {
  const { bpIdOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<UserGroupDto, Error, UserGroupMutationArgs>(
    ({ userId, groupId }) => bpIdOpsGateway.addUserToGroup(userId, groupId),
    {
      onSuccess: (dto, args) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [BpIdCacheKeys.BusinessUserGroups, args.userId],
          item: dto
        });
      }
    }
  );
};

export const useRemoveUserFromGroupMutation = () => {
  const { bpIdOpsGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<any, Error, UserGroupMutationArgs>(
    ({ userId, groupId }) =>
      bpIdOpsGateway.removeUserFromGroup(userId, groupId),
    {
      onSuccess: args => {
        cacheDeleteSingleItemInArray({
          queryClient,
          queryKey: [BpIdCacheKeys.BusinessUserGroups, args.userId],
          id: args.groupId
        });
      }
    }
  );
};

export const useSendUserInvite = () => {
  const queryClient = useQueryClient();
  const { bpIdOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return useMutation<UserInviteResponse, HttpError, UserInviteArgs>(
    bpIdOpsGateway.sendUserInvite,
    {
      onSuccess: () => {
        feedback.success("Invite has been sent!");
        invalidateInvitationsQueries(queryClient);
      }
    }
  );
};

export const useReSendUserInvite = () => {
  const { bpIdOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, HttpError, string>(bpIdOpsGateway.resendUserInvite, {
    onSuccess: () => {
      feedback.success("Invite has been re-sent!");
      invalidateInvitationsQueries(queryClient);
    },
    onError: error => {
      feedback.error(error?.message);
    }
  });
};

export const useCancelUserInvite = () => {
  const { bpIdOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, HttpError, string>(bpIdOpsGateway.cancelUserInvite, {
    onSuccess: () => {
      feedback.success("Invite has been canceled!");
      invalidateInvitationsQueries(queryClient);
    },
    onError: error => {
      feedback.error(error?.message);
    }
  });
};

export const useInvites = (args?: InvitationsArgs) => {
  const { bpIdOpsGateway } = useGateways();
  return useInfiniteQuery<PagingResponse<UserInviteDto>, HttpError>(
    [BpIdCacheKeys.Invitations, args],
    async page => {
      return await bpIdOpsGateway.fetchInvitations({
        ...args,
        pageParam: page.pageParam
      });
    },
    {
      getNextPageParam: lastPage => {
        return lastPage.next;
      }
    }
  );
};

export const useCloudApplicationsRefData = () => {
  const { bpIdOpsGateway } = useGateways();
  return useQuery<CloudApplicationsRefDataDto[], HttpError>(
    [BpIdCacheKeys.CloudApplicationsRefData],
    bpIdOpsGateway.getCloudApplicationsRefData,
    { staleTime: Infinity, cacheTime: Infinity }
  );
};

export const useLogoutAllSessions = () => {
  const { bpIdOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  return useMutation<boolean | undefined, HttpError, string>(
    bpIdOpsGateway.logoutAllSessions,
    {
      onSuccess: () => {
        feedback.success("All sessions logged out!");
      }
    }
  );
};

export const useBpIdUserDetails = (
  bpIdUserId: string,
  options?: Omit<UseQueryOptions<BpIdUserDto>, "queryKey" | "queryFn">
) => {
  const { bpIdOpsGateway } = useGateways();
  return useQuery<BpIdUserDto, HttpError>(
    [BpIdCacheKeys.BpIdentities, bpIdUserId],
    () => bpIdOpsGateway.getUserDetails(bpIdUserId),
    options
  );
};

export const useUserSignInLogs = (
  args: Pick<BpIdUserSignInLogArgs, "userId" | "startDate" | "endDate">
) => {
  const { bpIdOpsGateway } = useGateways();
  return useInfiniteQuery<
    PagingTableStorageResponse<BpIdUserSignInLog, BpIdUserSignInLogArgs>,
    HttpError
  >(
    [BpIdCacheKeys.BpIdSignInLogs, args],
    context => {
      return bpIdOpsGateway.getUserSignInLogs({
        ...args,
        nextPageToken: context.pageParam
      });
    },
    {
      getNextPageParam: lastPage => {
        return lastPage.args.nextPageToken;
      }
    }
  );
};

export const useAuditLogs = (
  args: Pick<BpIdAuditLogArgs, "startDate" | "endDate">
) => {
  const { bpIdOpsGateway } = useGateways();
  return useInfiniteQuery<
    PagingTableStorageResponse<BpIdAuditLog, BpIdAuditLogArgs>,
    HttpError
  >(
    [BpIdCacheKeys.BpIdAuditLogs, args],
    context => {
      return bpIdOpsGateway.getAuditLogs({
        ...args,
        nextPageToken: context.pageParam
      });
    },
    {
      getNextPageParam: lastPage => {
        return lastPage.args.nextPageToken;
      }
    }
  );
};

export const useClearBpId = (tenantId: string, userId: string) => {
  const { bpIdOpsGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<boolean | undefined, HttpError>(
    () => bpIdOpsGateway.userRemoveTenant(tenantId, userId),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([PltCacheKeys.User, userId]);
        feedback.success("BpId cleared!");
      }
    }
  );
};
