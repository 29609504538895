import { AxiosError } from "axios";
import {
  QueryClient,
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient
} from "react-query";
import {
  UseInfiniteQueryOptions,
  UseQueryOptions
} from "react-query/types/react/types";

import { HttpError, HttpStatusCode, NotFoundError } from "@bps/http-client";
import { useGateways } from "@libs/api/gateways-context";
import {
  AddLicenceDto,
  AddSalesProductDto,
  AddStripeCustomerDto,
  AddSubscriptionPayload,
  BillingContactDto,
  BusinessRoleDto,
  CancelCancelCustomerSubscriptionsArgs,
  ComponentActionDto,
  ComponentActionPayload,
  ComponentDefDto,
  ComponentDto,
  ComponentLiteDto,
  ComponentRequestDto,
  ComponentSettingDto,
  ComponentSettingsDto,
  ComponentWorkflowRunInstanceDto,
  CreateComponentPayload,
  CreateSettingPayload,
  CreateTenantDto,
  CustomerProductsDto,
  Feature,
  FeatureAction,
  GetComponentActionsArgs,
  GetComponentArgs,
  GetComponentsArgs,
  GetComponentsDefArgs,
  GetComponentSettingArgs,
  GetComponentsRequestsArgs,
  GetFeatureParams,
  GetLicencesArgs,
  GetSalesProductsArgs,
  GetTenantFeatureParams,
  InvoiceDto,
  LicenceDto,
  LicenceTypeDto,
  NewPltUser,
  NewTenantTag,
  PaymentMethodDto,
  PaymentMethodSetupDto,
  PaymentMethodUpdateArgs,
  PltUser,
  PreviewSubscriptionPayload,
  PromotionCodeDto,
  ResetCustomerSubscriptionBillingDateArgs,
  SalesProductDto,
  SecurityRolesAndPermissionsDto,
  StripeCustomerDto,
  SubscriptionDto,
  SubscriptionPreviewDto,
  Tenant,
  TenantDefaultUsersAuthenticationDto,
  TenantDto,
  TenantFeature,
  TenantFeatureDetails,
  TenantSearchArgs,
  TenantTag,
  UpdateSettingArgs,
  UpdateSettingPayload,
  UsedTenantTag,
  UserDefaultAuthenticationDto
} from "@libs/api/gateways/plt/plt-gateway.dtos";
import { guid } from "@libs/common/guid";
import { PagingResponse } from "@libs/paging/paging-response.type";
import {
  cacheDeleteSingleItemInArray,
  cacheUpsertSingleItemInArray
} from "@libs/react-query/react-query-cache.utils";
import { useRootStore } from "@stores/StoresProvider";

import { RefDataDto } from "../../types/common-dtos";

export const PltCacheKeys = {
  Tenants: "plt-tenants",
  TenantTags: "plt-tenanttags",
  UsedTenantTags: "plt-usedtenanttags",
  Users: "plt-users",
  TenantFeature: "tenant-feature",
  UsersSearch: "plt-users-search",
  User: "plt-user",
  UserDefaultAuthentication: "user-default-auth",
  Feature: "feature",
  SecurityRolesAndPermissions: "security-roles-and-permissions",
  BusinessRoles: "business-roles",
  TenantFeatures: "tenant-features",
  TenantLicences: "tenant-licences",
  Licence: "licence",
  LicenceTypes: "licence-types",
  SalesProducts: "sales-products",
  SalesProductsGroups: "sales-products-groups",
  ApplicationsRef: "applications-ref-data",
  TenantDefaultUserAuthentication: "tenant-default-user-auth",
  BillingContact: "billing-contact",
  BillingHistory: "billing-history",
  BillingInvoice: "billing-invoice",
  TenantPaymentMethods: "tenant-payment-methods",
  TenantDefaultPaymentMethod: "tenant-default-payment-methods",
  TenantStripeCustomer: "tenant-stripe-customer",
  ChildTenants: "child-tenants",
  CustomerSubscriptionsProducts: "customer-subscriptions-products",
  HierarchyTypesRef: "hierarchyTypes-ref-data",
  TenantPromotionCodes: "tenant-promotion-codes",
  ComponentsDefs: "components-defs",
  ComponentsLite: "components-lite",
  Components: "components",
  ComponentActions: "component-actions",
  ComponentActionsRequests: "components-actions-requests",
  ComponentSettings: "component-settings",
  ComponentSetting: "component-setting",
  ComponentWorkflowRunInstance: "component-workflow-run-instance",
  ComponentManagerWorkflowRunInstance: "component-manager-workflow-run-instance"
};

// Tenant
export const useTenantsPltQuery = (
  args?: Omit<TenantSearchArgs, "pageParam">,
  options?: Omit<
    UseInfiniteQueryOptions<PagingResponse<Tenant>, HttpError>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();

  return useInfiniteQuery<PagingResponse<Tenant>, AxiosError>(
    [PltCacheKeys.Tenants, args],
    page => {
      return platformGateway.getTenants({
        ...args,
        pageParam: page.pageParam
      });
    },
    {
      ...options,
      getNextPageParam: lastPage => {
        return lastPage.next;
      }
    }
  );
};

export const useTenantQuery = (
  id: string,
  options?: Omit<UseQueryOptions<Tenant, Error>, "queryKey" | "queryFn">
) => {
  const { platformGateway } = useGateways();
  return useQuery<Tenant, HttpError>(
    [PltCacheKeys.Tenants, id],
    () => platformGateway.getTenant(id),
    {
      ...options
    }
  );
};

export const useUpdateTenantCustomerAccount = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    Tenant,
    HttpError,
    { tenantId: guid; customerTenantId: string }
  >(
    ({ tenantId, customerTenantId }) => {
      return platformGateway.updateTenantCustomerAccount(
        tenantId,
        customerTenantId
      );
    },
    {
      onSuccess: async tenantUpdate => {
        queryClient.setQueryData(
          [PltCacheKeys.Tenants, tenantUpdate.id],
          tenantUpdate
        );

        await queryClient.invalidateQueries([
          PltCacheKeys.ChildTenants,
          tenantUpdate.customerTenantId
        ]);
        feedback.success("Tenant details updated!");
      },
      onError: err => {
        feedback.error(err);
      }
    }
  );
};

export const useTenantFeatureQuery = (tenantId: guid, featureCode: string) => {
  const { platformGateway } = useGateways();

  return useQuery<TenantFeatureDetails | undefined>(
    [PltCacheKeys.TenantFeature, { tenantId, featureCode }],
    async () => {
      return await platformGateway.getTenantFeature(tenantId, featureCode);
    }
  );
};

export const useEnableDisableTenantFeatureMutation = () => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<
    any,
    Error,
    { tenantId: guid; featureCode: TenantFeature; action: FeatureAction }
  >(
    ({ tenantId, featureCode, action }) =>
      platformGateway.enableDisableFeature(tenantId, featureCode, action),
    {
      onSuccess: async (_, { tenantId, featureCode, action }) => {
        // Set the cache so that the link is disabled until the cache time expires and is
        // rechecked since provisioning a feature is an asynchronous process
        queryClient.setQueryData(
          [PltCacheKeys.TenantFeature, { tenantId, featureCode, action }],
          {
            id: "unknown",
            tenantId,
            feature: featureCode,
            action
          }
        );
      }
    }
  );
};

export const useEncryptTenantResourcesMutation = () => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<any, Error, { tenantId: string }>(
    ({ tenantId }) => platformGateway.encryptTenantResources(tenantId),
    {
      onSuccess: async (_, { tenantId }) => {
        // Set the cache so that the link is disabled until the cache time expires and is rechecked since this is an asynchronous process
        queryClient.setQueryData([PltCacheKeys.TenantFeature, { tenantId }], {
          id: "unknown",
          tenantId
        });
      }
    }
  );
};

export const useActivateTenantMutation = () => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { tenantId: string }>(
    ({ tenantId }) => platformGateway.activateTenant(tenantId),
    {
      onSuccess: async (_, { tenantId }) => {
        // Set the cache so that the link is disabled until the cache time expires and is rechecked since this is an asynchronous process
        queryClient.setQueryData([PltCacheKeys.TenantFeature, { tenantId }], {
          id: "unknown",
          tenantId
        });
      }
    }
  );
};

export const useDeactivateTenantMutation = () => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { tenantId: string }>(
    ({ tenantId }) => platformGateway.deactivateTenant(tenantId),
    {
      onSuccess: async (_, { tenantId }) => {
        // Set the cache so that the link is disabled until the cache time expires and is rechecked since this is an asynchronous process
        queryClient.setQueryData([PltCacheKeys.TenantFeature, { tenantId }], {
          id: "unknown",
          tenantId
        });
      }
    }
  );
};

export const useUpdateTenantIsTemporary = () => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useMutation<Tenant, Error, { tenantId: guid; isTemporary: boolean }>(
    ({ tenantId, isTemporary }) => {
      return platformGateway.updateTenantIsTemporary(tenantId, isTemporary);
    },
    {
      onSuccess: async tenantUpdate => {
        const tenants = queryClient.getQueryData<Tenant[]>([
          PltCacheKeys.Tenants,
          null
        ]);

        const tenant = tenants?.find(t => t.id === tenantUpdate.id);
        if (tenant) {
          cacheUpsertSingleItemInArray({
            queryClient,
            queryKey: [PltCacheKeys.Tenants, null],
            item: {
              ...tenant,
              isTemporary: tenantUpdate.isTemporary,
              eTag: tenantUpdate.eTag
            }
          });
        }
      }
    }
  );
};

export const useTenantDefaultUserAuth = (tenantId: guid) => {
  const { platformGateway } = useGateways();

  return useQuery<TenantDefaultUsersAuthenticationDto, AxiosError>(
    [PltCacheKeys.TenantDefaultUserAuthentication, tenantId],
    async () => {
      try {
        return await platformGateway.getTenantDefaultUsersAuthentication(
          tenantId
        );
      } catch (e) {
        if (e instanceof NotFoundError) {
          return undefined;
        }
        return e;
      }
    }
  );
};

export const useAddTenantDefaultUserAuth = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();

  const queryClient = useQueryClient();

  return useMutation<
    TenantDefaultUsersAuthenticationDto,
    HttpError,
    TenantDefaultUsersAuthenticationDto
  >(platformGateway.addTenantDefaultUsersAuthentication, {
    onSuccess: async dto => {
      queryClient.setQueryData(
        [PltCacheKeys.TenantDefaultUserAuthentication, dto.id],
        dto
      );
      feedback.success("Tenant details have been updated successfully!");
    }
  });
};

export const useUpdateTenantDefaultUserAuth = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    TenantDefaultUsersAuthenticationDto,
    HttpError,
    TenantDefaultUsersAuthenticationDto
  >(platformGateway.updateTenantDefaultUsersAuthentication, {
    onSuccess: async dto => {
      queryClient.setQueryData(
        [PltCacheKeys.TenantDefaultUserAuthentication, dto.id],
        dto
      );
      feedback.success("Tenant details have been updated successfully!");
    }
  });
};

export const useChildTenants = (
  customerTenantId: string,
  options?: Omit<UseQueryOptions<Tenant[], AxiosError>, "queryKey" | "queryFn">
) => {
  const { platformGateway } = useGateways();

  return useQuery<Tenant[], AxiosError>(
    [PltCacheKeys.ChildTenants, customerTenantId],
    async () => {
      try {
        return await platformGateway.getChildTenants(customerTenantId);
      } catch (e) {
        if (e instanceof NotFoundError) {
          return undefined;
        }
        return e;
      }
    },
    options
  );
};

export const useUpdateTenant = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<Tenant, HttpError, TenantDto>(
    platformGateway.updateTenant,
    {
      onSuccess: async dto => {
        const prevData = queryClient.getQueryData<Tenant>([
          PltCacheKeys.Tenants,
          dto.id
        ]);
        // update current get tenant query
        queryClient.setQueryData([PltCacheKeys.Tenants, dto.id], {
          ...prevData,
          ...dto
        });

        // get all search tenant queries where query key contains CacheKeys.Tenants and data is in array
        const queries = queryClient.getQueryCache().getAll();
        const tenantQueries = queries.filter(
          s =>
            s.queryKey.includes(PltCacheKeys.Tenants) &&
            Array.isArray(s.state.data)
        );

        // update all search tenants queries
        tenantQueries.forEach(query => {
          const prevData = queryClient.getQueryData<Tenant>(query.queryKey);
          cacheUpsertSingleItemInArray({
            queryClient,
            queryKey: query.queryKey,
            item: {
              ...prevData,
              ...dto
            }
          });
        });

        feedback.success("Tenant details have been updated successfully!");
      }
    }
  );
};

export const useCreateTenant = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<Tenant, HttpError, CreateTenantDto>(
    platformGateway.createTenant,
    {
      onSuccess: async dto => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltCacheKeys.Tenants],
          item: dto
        });

        await queryClient.invalidateQueries([
          PltCacheKeys.ChildTenants,
          dto.customerTenantId
        ]);

        feedback.success("Tenant has been created successfully!");
      }
    }
  );
};

// Plt Users
export const useUser = (tenantId: guid, userId: string) => {
  const { platformGateway } = useGateways();
  return useQuery<PltUser, HttpError>([PltCacheKeys.User, userId], () =>
    platformGateway.getUser(tenantId, userId)
  );
};

export const useSearchUsers = (
  tenantId?: string,
  searchTerm?: string,
  bpIdUserId?: string
) => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useQuery<PltUser[], AxiosError>(
    [PltCacheKeys.UsersSearch, tenantId, searchTerm, bpIdUserId],
    async () =>
      searchTerm || tenantId || bpIdUserId
        ? await platformGateway.searchUsers(tenantId, searchTerm, bpIdUserId)
        : [],
    {
      onSuccess: data => {
        queryClient.setQueryData([PltCacheKeys.UsersSearch], data);
      }
    }
  );
};

export const useCreateUser = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<PltUser, HttpError, NewPltUser>(
    platformGateway.createUser,
    {
      onSuccess: async dto => {
        const queries = queryClient.getQueryCache().getAll();
        const userSearchQuery = queries.filter(q =>
          q.queryKey.includes(PltCacheKeys.UsersSearch)
        );

        userSearchQuery.forEach(query => {
          cacheUpsertSingleItemInArray({
            queryClient,
            queryKey: query.queryKey,
            item: dto,
            asFirstItem: true
          });
        });

        feedback.success("A new user has been created successfully!");
      }
    }
  );
};

export const useUpdateUser = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<PltUser, HttpError, PltUser>(platformGateway.updateUser, {
    onSuccess: async dto => {
      const queries = queryClient.getQueryCache().getAll();
      const userSearchQuery = queries.filter(q =>
        q.queryKey.includes(PltCacheKeys.UsersSearch)
      );

      userSearchQuery.forEach(query => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: query.queryKey,
          item: dto
        });
      });

      // Force invalidate of the query for the updated user
      // Because the user data is being used on a form, we want to use "Refetch" in place of invalidate
      // as the form values are loaded before the "invalidate" action gets the new values from the api
      await queryClient.refetchQueries([PltCacheKeys.User, dto.id]);

      feedback.success("The user data has been updated successfully!");
    }
  });
};

export const useUserDefaultAuth = (tenantId: guid, userId: guid) => {
  const { platformGateway } = useGateways();

  return useQuery<UserDefaultAuthenticationDto, AxiosError>(
    [PltCacheKeys.UserDefaultAuthentication, tenantId, userId],
    async () => {
      try {
        return await platformGateway.getUserDefaultAuthentication(
          tenantId,
          userId
        );
      } catch (e) {
        if (e instanceof NotFoundError) {
          return undefined;
        }
        return e;
      }
    }
  );
};

export const useAddUserDefaultAuth = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    UserDefaultAuthenticationDto,
    HttpError,
    UserDefaultAuthenticationDto
  >(platformGateway.addUserDefaultAuthentication, {
    onSuccess: async dto => {
      queryClient.setQueryData(
        [PltCacheKeys.UserDefaultAuthentication, dto.tenantId, dto.userId],
        dto
      );
      feedback.success("User auth details have been updated successfully!");
    }
  });
};

export const useUpdateUserDefaultAuth = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    UserDefaultAuthenticationDto,
    HttpError,
    UserDefaultAuthenticationDto
  >(platformGateway.updateUserDefaultAuthentication, {
    onSuccess: async dto => {
      queryClient.setQueryData(
        [PltCacheKeys.UserDefaultAuthentication, dto.tenantId, dto.userId],
        dto
      );
      feedback.success("User auth details have been updated successfully!");
    }
  });
};

export const useFeature = (params?: GetFeatureParams) => {
  const { platformGateway } = useGateways();
  return useQuery<Feature[]>([PltCacheKeys.Feature, params], async () => {
    return await platformGateway.getFeature(params);
  });
};

export const useSecurityRolesAndPermissions = () => {
  const { platformGateway } = useGateways();

  return useQuery<SecurityRolesAndPermissionsDto, Error>(
    [PltCacheKeys.SecurityRolesAndPermissions],
    () => platformGateway.getSecurityRolesAndPermissions()
  );
};

export const useTenantFeatures = (params: GetTenantFeatureParams) => {
  const { platformGateway } = useGateways();
  return useQuery<TenantFeatureDetails[]>(
    [PltCacheKeys.TenantFeatures],
    async () => {
      return await platformGateway.getTenantFeatures(params);
    }
  );
};

export const useBusinessRoles = (businessRoles?: string[]) => {
  const { platformGateway } = useGateways();
  return useQuery<BusinessRoleDto[], Error>(
    [PltCacheKeys.BusinessRoles],
    async () => {
      return await platformGateway.getBusinessRole(businessRoles);
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};

// Licences types
export const useLicenceTypes = () => {
  const { platformGateway } = useGateways();
  return useQuery<LicenceTypeDto[], Error>(
    [PltCacheKeys.LicenceTypes],
    platformGateway.getLicenceTypes
  );
};

export const useLicenceType = (
  code: string,
  options?: Omit<UseQueryOptions<LicenceTypeDto>, "queryKey" | "queryFn">
) => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useQuery<LicenceTypeDto, Error>(
    [PltCacheKeys.LicenceTypes, code],
    () => platformGateway.getLicenceType(code),
    {
      ...options,
      initialData: () => {
        const cache = queryClient.getQueryData<LicenceTypeDto[]>([
          PltCacheKeys.LicenceTypes
        ]);
        return cache?.find(d => d.code === code);
      }
    }
  );
};

export const useAddLicenceType = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<LicenceTypeDto, HttpError, LicenceTypeDto>(
    platformGateway.addLicenceType,
    {
      onSuccess: async dto => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltCacheKeys.LicenceTypes],
          item: dto,
          isIdentityEqual: item => item.code === dto.code
        });
        feedback.success("A new licence type has been added successfully!");
        queryClient.invalidateQueries([PltCacheKeys.LicenceTypes, dto.code]);
      }
    }
  );
};

export const useUpdateLicenceType = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<LicenceTypeDto, HttpError, LicenceTypeDto>(
    platformGateway.updateLicenceType,
    {
      onSuccess: async dto => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltCacheKeys.LicenceTypes],
          item: dto,
          isIdentityEqual: item => item.code === dto.code
        });
        feedback.success("The licence type has been updated successfully!");
        queryClient.invalidateQueries([PltCacheKeys.LicenceTypes, dto.code]);
      }
    }
  );
};

export const useToggleLicenceType = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<void, Error, { code: string; disable?: boolean }>(
    ({ code, disable }) =>
      disable
        ? platformGateway.disableLicenceType(code)
        : platformGateway.enableLicenceType(code),
    {
      onSuccess: async (dto, args) => {
        await queryClient.invalidateQueries([PltCacheKeys.LicenceTypes]);
        feedback.success(
          `A new licence type has been ${
            args.disable ? "disabled" : "enabled"
          }!`
        );
      },
      onError: error => {
        feedback.error(error.message);
      }
    }
  );
};

// Licences

const updateLicencesQueryKey = (
  queryClient: QueryClient,
  dto: LicenceDto,
  asFirstItem?: boolean
) => {
  const queries = queryClient.getQueryCache().getAll();
  const userSearchQuery = queries.filter(q =>
    q.queryKey.includes(PltCacheKeys.TenantLicences)
  );

  userSearchQuery.forEach(query => {
    cacheUpsertSingleItemInArray({
      queryClient,
      queryKey: query.queryKey,
      item: dto,
      asFirstItem
    });
  });
};
export const useLicence = (
  id: string,
  options?: Omit<UseQueryOptions<LicenceDto>, "queryKey" | "queryFn">
) => {
  const { platformGateway } = useGateways();

  return useQuery<LicenceDto>(
    [PltCacheKeys.Licence, id],
    () => platformGateway.getLicence(id),
    {
      ...options
    }
  );
};

export const useLicences = (args: GetLicencesArgs) => {
  const { platformGateway } = useGateways();

  return useQuery<LicenceDto[], AxiosError>(
    [PltCacheKeys.TenantLicences, args],
    () => platformGateway.getLicences(args)
  );
};

export const useAddLicence = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<LicenceDto, HttpError, AddLicenceDto>(
    platformGateway.addLicence,
    {
      onSuccess: dto => {
        updateLicencesQueryKey(queryClient, dto, true);
        feedback.success("A new licence has been added successfully!");
      }
    }
  );
};

export const useUpdateLicence = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<LicenceDto, HttpError, LicenceDto>(
    platformGateway.updateLicence,
    {
      onSuccess: async dto => {
        updateLicencesQueryKey(queryClient, dto);
        queryClient.setQueryData([PltCacheKeys.Licence, dto.id], dto);
        feedback.success("The licence has been updated successfully!");
      }
    }
  );
};

export const useRemoveLicence = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<LicenceDto, Error, string>(platformGateway.removeLicence, {
    onSuccess: async dto => {
      cacheDeleteSingleItemInArray({
        queryClient,
        queryKey: [PltCacheKeys.TenantLicences, dto.tenantId],
        id: dto.id
      });
      feedback.success("The licence has been inactivated!");
    }
  });
};

// Products
const getSalesProductsCurrentQueryKey = (queryClient: QueryClient) => {
  const queries = queryClient.getQueryCache();
  const query = queries
    .getAll()
    .find(s => s.queryKey.includes(PltCacheKeys.SalesProducts));

  if (query) {
    return query.queryKey;
  }

  return [PltCacheKeys.SalesProducts];
};

export const useSalesProducts = (
  args?: GetSalesProductsArgs,
  options?: Omit<UseQueryOptions<SalesProductDto[]>, "queryKey" | "queryFn">
) => {
  const queryKey = args
    ? [PltCacheKeys.SalesProducts, args]
    : PltCacheKeys.SalesProducts;

  const { platformGateway } = useGateways();
  return useQuery<SalesProductDto[], AxiosError>(
    queryKey,
    async () => {
      return await platformGateway.getSalesProducts(args);
    },
    options
  );
};

export const useSalesProduct = (
  id: string,
  options?: Omit<
    UseQueryOptions<SalesProductDto, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useQuery<SalesProductDto, AxiosError>(
    [PltCacheKeys.SalesProducts, id],
    () => platformGateway.getSalesProduct(id),
    {
      ...options,
      initialData: () => {
        const cache = queryClient.getQueryData<SalesProductDto[]>(
          PltCacheKeys.SalesProducts
        );
        return cache?.find(d => d.id === id);
      }
    }
  );
};

export const useAddSalesProduct = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<SalesProductDto, HttpError, AddSalesProductDto>(
    platformGateway.addSalesProduct,
    {
      onSuccess: dto => {
        const queryKey = getSalesProductsCurrentQueryKey(queryClient);
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey,
          item: dto
        });
        feedback.success("A new product has been added successfully!");
      }
    }
  );
};

export const useUpdateSalesProduct = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<SalesProductDto, HttpError, SalesProductDto>(
    platformGateway.updateSalesProduct,
    {
      onSuccess: dto => {
        const queryKey = getSalesProductsCurrentQueryKey(queryClient);
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey,
          item: dto
        });
        queryClient.setQueryData([PltCacheKeys.SalesProducts, dto.id], dto);
        feedback.success("The product has been updated successfully!");
      }
    }
  );
};

export const useResyncStripeProducts = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();

  return useMutation<void, Error>(
    () => platformGateway.resyncStripeProducts(),
    {
      onSuccess: () => {
        feedback.success("The Stripe products have been synced successfully!");
      },
      onError: () => {
        feedback.error("Error occurred while syncing the Stripe products!");
      }
    }
  );
};

export const useApplicationsRefData = () => {
  const { platformGateway } = useGateways();

  return useQuery<RefDataDto[], AxiosError>(
    [PltCacheKeys.ApplicationsRef],
    platformGateway.getApplicationsRefData,
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};

export const useHierarchyTypesRefData = () => {
  const { platformGateway } = useGateways();

  return useQuery<RefDataDto[], AxiosError>(
    [PltCacheKeys.HierarchyTypesRef],
    platformGateway.getHierarchyTypesRefData,
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};

// Billing and payment methods
export const useBillingContact = (
  tenantId: string,
  options?: Omit<UseQueryOptions<BillingContactDto>, "queryKey" | "queryFn">
) => {
  const { platformGateway } = useGateways();
  return useQuery<BillingContactDto>(
    [PltCacheKeys.BillingContact, tenantId],
    () => platformGateway.getBillingContact(tenantId),
    options
  );
};

export const useBillingHistory = (
  tenantId: string,
  options?: Omit<
    UseQueryOptions<InvoiceDto[], AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();

  return useQuery<InvoiceDto[], AxiosError>(
    [PltCacheKeys.BillingHistory, tenantId],
    () => platformGateway.getBillingHistory(tenantId),
    options
  );
};

export const useBillingInvoice = (
  tenantId: string,
  invoiceId: string,
  options?: Omit<
    UseQueryOptions<InvoiceDto, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();

  return useQuery<InvoiceDto, AxiosError>(
    [PltCacheKeys.BillingHistory, tenantId, invoiceId],
    () => platformGateway.getInvoice(tenantId, invoiceId),
    options
  );
};

export const usePayInvoice = () => {
  const queryClient = useQueryClient();
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();

  return useMutation<
    InvoiceDto,
    AxiosError,
    { tenantId: string; invoiceNumber: string }
  >(
    ({ tenantId, invoiceNumber }) =>
      platformGateway.payInvoice(tenantId, invoiceNumber),
    {
      onSuccess: (dto, args) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltCacheKeys.BillingHistory, args.tenantId],
          item: dto
        });
        feedback.success("The invoice has been paid!");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useTenantPaymentMethods = (
  customerTenantId: string,
  options?: Omit<
    UseQueryOptions<PaymentMethodDto[], AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();

  return useQuery<PaymentMethodDto[], AxiosError>(
    [PltCacheKeys.TenantPaymentMethods, customerTenantId],
    () => platformGateway.getPaymentMethods(customerTenantId),
    options
  );
};

export const useTenantDefaultPaymentMethod = (
  customerTenantId: string,
  options?: Omit<
    UseQueryOptions<PaymentMethodDto, AxiosError>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useQuery<PaymentMethodDto, AxiosError>(
    [PltCacheKeys.TenantDefaultPaymentMethod, customerTenantId],
    () => platformGateway.getDefaultPaymentMethod(customerTenantId),
    {
      ...options,
      onSuccess: async dto => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltCacheKeys.TenantPaymentMethods, customerTenantId],
          item: dto
        });
        await invalidateCustomerSubscriptionsProductsQuery(
          queryClient,
          customerTenantId
        );
      }
    }
  );
};

export const useUpdateTenantPaymentMethods = () => {
  const queryClient = useQueryClient();
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();

  return useMutation<PaymentMethodDto, AxiosError, PaymentMethodUpdateArgs>(
    platformGateway.updatePaymentMethod,
    {
      onSuccess: async (dto, args) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          queryKey: [PltCacheKeys.TenantPaymentMethods, args.customerTenantId],
          item: dto
        });
        await invalidateCustomerSubscriptionsProductsQuery(
          queryClient,
          args.customerTenantId
        );
        feedback.success("The method has been updated!");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useDeleteTenantPaymentMethods = (customerTenantId: string) => {
  const queryClient = useQueryClient();
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();

  return useMutation<void, AxiosError, string>(
    async paymentMethodId => {
      await platformGateway.deletePaymentMethod(
        customerTenantId,
        paymentMethodId
      );

      const methods =
        queryClient.getQueryData<PaymentMethodDto[]>([
          PltCacheKeys.TenantPaymentMethods,
          customerTenantId
        ]) ?? [];

      const cardToBeDeleted = methods.find(m => m.id === paymentMethodId);
      const remainedCards = methods.filter(m => m.id !== paymentMethodId);

      // In a case we delete the default payment method, we are setting the first remaining as default one!
      if (
        cardToBeDeleted &&
        cardToBeDeleted.isDefault &&
        remainedCards.length
      ) {
        await platformGateway.setupPaymentMethodAsDefault(
          customerTenantId,
          remainedCards[0].id
        );
      }
    },
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries([
          PltCacheKeys.TenantPaymentMethods,
          customerTenantId
        ]);

        queryClient.removeQueries([
          PltCacheKeys.TenantDefaultPaymentMethod,
          customerTenantId
        ]);

        await invalidateCustomerSubscriptionsProductsQuery(
          queryClient,
          customerTenantId
        );
        feedback.success("The method has been deleted!");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useTenantPaymentMethodsAsDefault = (customerTenantId: string) => {
  const queryClient = useQueryClient();
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();

  return useMutation<PaymentMethodDto, AxiosError, string>(
    paymentMethodId =>
      platformGateway.setupPaymentMethodAsDefault(
        customerTenantId,
        paymentMethodId
      ),
    {
      onSuccess: async dto => {
        await queryClient.invalidateQueries([
          PltCacheKeys.TenantPaymentMethods,
          customerTenantId
        ]);

        await invalidateCustomerSubscriptionsProductsQuery(
          queryClient,
          customerTenantId
        );

        queryClient.setQueryData(
          [PltCacheKeys.TenantDefaultPaymentMethod, customerTenantId],
          dto
        );

        feedback.success("The method has been updated!");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

export const useSetupTenantPaymentMethods = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();

  return useMutation<PaymentMethodSetupDto, AxiosError, string>(
    platformGateway.setupPaymentMethod,
    {
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

// Stripe customer
export const useStripeCustomer = (
  tenantId: string,
  options?: Omit<
    UseQueryOptions<StripeCustomerDto, Error>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();

  return useQuery<StripeCustomerDto, Error>(
    [PltCacheKeys.TenantStripeCustomer, tenantId],
    () => platformGateway.getStripeCustomer(tenantId),
    {
      ...options,
      onError: e => {
        if (e instanceof NotFoundError) {
          return undefined;
        }
        return e;
      }
    }
  );
};

export const useAddStripeCustomer = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<StripeCustomerDto, AxiosError, AddStripeCustomerDto>(
    platformGateway.addStripeCustomer,
    {
      onSuccess: data => {
        queryClient.setQueryData(
          [PltCacheKeys.TenantStripeCustomer, data.tenantId],
          data
        );
        queryClient.invalidateQueries([PltCacheKeys.Tenants, data.tenantId]);

        feedback.success("A new Stripe customer has been created!");
      },
      onError: e => {
        feedback.error(e.message);
      }
    }
  );
};

// Subscriptions

const invalidateCustomerSubscriptionsProductsQuery = async (
  queryClient: QueryClient,
  customerTenantId: string
) => {
  await queryClient.invalidateQueries([
    PltCacheKeys.CustomerSubscriptionsProducts,
    customerTenantId
  ]);
};
export const useCustomerProducts = (
  customerTenantId: string,
  options?: Omit<
    UseQueryOptions<CustomerProductsDto, HttpError>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();

  return useQuery<CustomerProductsDto, HttpError>(
    [PltCacheKeys.CustomerSubscriptionsProducts, customerTenantId],
    () => platformGateway.getCustomerProducts(customerTenantId),
    {
      ...options
    }
  );
};

export const useCustomerSubscriptionPreview = () => {
  const { platformGateway } = useGateways();

  return useMutation<
    SubscriptionPreviewDto,
    HttpError,
    PreviewSubscriptionPayload
  >(platformGateway.getCustomerSubscriptionPreview);
};

export const useAddCustomerSubscription = (customerTenantId: string) => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<SubscriptionPreviewDto, HttpError, AddSubscriptionPayload>(
    platformGateway.addCustomerSubscription,
    {
      onSuccess: async () => {
        await invalidateCustomerSubscriptionsProductsQuery(
          queryClient,
          customerTenantId
        );

        await queryClient.refetchQueries([
          PltCacheKeys.BillingHistory,
          customerTenantId
        ]);
        feedback.success("A new subscription has been updated!");
      }
    }
  );
};

export const useCancelCustomerSubscriptions = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    SubscriptionDto,
    AxiosError,
    CancelCancelCustomerSubscriptionsArgs
  >(platformGateway.cancelCustomerSubscription, {
    onSuccess: async dto => {
      await invalidateCustomerSubscriptionsProductsQuery(
        queryClient,
        dto.customerTenantId
      );
      feedback.success("The subscription has been canceled!");
    },
    onError: e => {
      feedback.error(e.message);
    }
  });
};

export const useUndoCancellationCustomerSubscription = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    SubscriptionDto,
    AxiosError,
    Omit<CancelCancelCustomerSubscriptionsArgs, "cancelImmediately">
  >(platformGateway.undoCancellationCustomerSubscription, {
    onSuccess: async dto => {
      await invalidateCustomerSubscriptionsProductsQuery(
        queryClient,
        dto.customerTenantId
      );
      feedback.success("The subscription cancellation has been updated!");
    },
    onError: e => {
      feedback.error(e.message);
    }
  });
};

export const useResetCustomerSubscriptionBillingDate = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    SubscriptionDto,
    AxiosError,
    ResetCustomerSubscriptionBillingDateArgs
  >(platformGateway.resetCustomerSubscriptionBillingDate, {
    onSuccess: async dto => {
      await invalidateCustomerSubscriptionsProductsQuery(
        queryClient,
        dto.customerTenantId
      );
      feedback.success("The subscription billing date has been reset!");
    },
    onError: e => {
      feedback.error(e.message);
    }
  });
};

export const useTenantPromotionCodes = (
  tenantId: string,
  options?: Omit<
    UseQueryOptions<PromotionCodeDto[], HttpError>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();

  return useQuery<PromotionCodeDto[], HttpError>(
    [PltCacheKeys.TenantPromotionCodes, tenantId],
    () => platformGateway.getTenantPromotionCodes(tenantId),
    options
  );
};

export const useTenantPromotionCode = (
  tenantId: string,
  promotionCodeId: string
) => {
  const { platformGateway } = useGateways();
  const queryClient = useQueryClient();

  return useQuery<PromotionCodeDto, AxiosError>(
    [PltCacheKeys.TenantPromotionCodes, tenantId, promotionCodeId],
    () => platformGateway.getTenantPromotionCode(tenantId, promotionCodeId),
    {
      onSuccess: (dto: PromotionCodeDto) => {
        cacheUpsertSingleItemInArray({
          queryClient,
          item: dto,
          queryKey: [PltCacheKeys.TenantPromotionCodes, tenantId]
        });
      }
    }
  );
};

// COMPONENTS
const invalidateComponentsQueries = async (queryClient: QueryClient) => {
  const queries = queryClient.getQueryCache().getAll();
  const componentsQueries = queries.filter(
    q =>
      q.queryKey.includes(PltCacheKeys.ComponentsLite) ||
      q.queryKey.includes(PltCacheKeys.Components)
  );

  setTimeout(async () => {
    await Promise.all(
      componentsQueries.map(query => queryClient.invalidateQueries(query))
    );
  }, 1000);
};

export const useComponentsDefs = (args?: GetComponentsDefArgs) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentDefDto[], HttpError>(
    [PltCacheKeys.ComponentsDefs, args],
    () => platformGateway.getComponentsDefs(args),
    {
      cacheTime: Infinity,
      staleTime: Infinity
    }
  );
};

export const useComponents = (args?: GetComponentsArgs) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentLiteDto[], HttpError>(
    [PltCacheKeys.ComponentsLite, args],
    () => platformGateway.getComponents(args)
  );
};

export const useComponent = (args?: GetComponentArgs) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentDto, HttpError>(
    [PltCacheKeys.Components, args?.tenantId, args],
    () => platformGateway.getComponent(args)
  );
};

export const useAddOrUpdateComponent = (message: string) => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ComponentDto, HttpError, CreateComponentPayload>(
    platformGateway.addOrUpdateComponent,
    {
      onSuccess: async () => {
        await invalidateComponentsQueries(queryClient);
        feedback.success(message);
      }
    }
  );
};

export const useInitializeComponent = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ComponentDto, HttpError, ComponentActionPayload>(
    platformGateway.initializeComponent,
    {
      onSuccess: async () => {
        await invalidateComponentsQueries(queryClient);
        feedback.success("Component initialized");
      },
      onError: e => {
        feedback.error(e);
      }
    }
  );
};

export const useDeInitializeComponent = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ComponentDto, HttpError, ComponentActionPayload>(
    platformGateway.deInitializeComponent,
    {
      onSuccess: async () => {
        await invalidateComponentsQueries(queryClient);
        feedback.success("Component de-initialized");
      },
      onError: e => {
        feedback.error(e);
      }
    }
  );
};

export const useArchiveComponent = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ComponentDto, HttpError, ComponentActionPayload>(
    platformGateway.archiveComponent,
    {
      onSuccess: async () => {
        await invalidateComponentsQueries(queryClient);
        feedback.success("Component archived");
      },
      onError: e => {
        feedback.error(e);
      }
    }
  );
};

export const useReConfigureComponent = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ComponentDto, HttpError, ComponentActionPayload>(
    platformGateway.archiveComponent,
    {
      onSuccess: async () => {
        await invalidateComponentsQueries(queryClient);
        feedback.success("Component re-configured");
      },
      onError: e => {
        feedback.error(e);
      }
    }
  );
};

export const useDisableComponent = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ComponentDto, HttpError, ComponentActionPayload>(
    platformGateway.disableComponent,
    {
      onSuccess: async () => {
        await invalidateComponentsQueries(queryClient);
        feedback.success("Component disabled");
      },
      onError: e => {
        feedback.error(e);
      }
    }
  );
};

export const useComponentActions = (args: GetComponentActionsArgs) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentActionDto[], HttpError>(
    [PltCacheKeys.ComponentActions, args.componentId],
    () => platformGateway.getComponentActions(args)
  );
};

export const useComponentSettings = (args: ComponentActionPayload) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentSettingsDto, HttpError>(
    [PltCacheKeys.ComponentSettings, args],
    () => platformGateway.getComponentSettings(args)
  );
};

export const useComponentSetting = (args: GetComponentSettingArgs) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentSettingDto, HttpError>(
    [PltCacheKeys.ComponentSetting, args],
    () => platformGateway.getComponentSetting(args)
  );
};

export const useCreateComponentSetting = (args: ComponentActionPayload) => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<
    Omit<ComponentDto, "implementations" | "id" | "eTag" | "changeLog">,
    HttpError,
    CreateSettingPayload
  >(payload => platformGateway.createComponentSettings(args, payload), {
    onSuccess: async () => {
      await invalidateComponentsQueries(queryClient);
      feedback.success("Component updated");
    }
  });
};

export const useUpdateComponentSetting = (args: UpdateSettingArgs) => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<ComponentSettingDto, HttpError, UpdateSettingPayload>(
    payload => platformGateway.updateComponentSettings(args, payload),
    {
      onSuccess: async () => {
        await invalidateComponentsQueries(queryClient);
        feedback.success("Component updated");
      }
    }
  );
};

export const useComponentsRequests = (
  args: GetComponentsRequestsArgs,
  options?: Omit<
    UseQueryOptions<ComponentRequestDto[], Error>,
    "queryKey" | "queryFn"
  >
) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentRequestDto[], HttpError>(
    [PltCacheKeys.ComponentActionsRequests, args],
    () => platformGateway.getComponentsRequests(args),
    options
  );
};

export const useComponentWorkflowRunInstance = (id: string) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentWorkflowRunInstanceDto, HttpError>(
    [PltCacheKeys.ComponentWorkflowRunInstance, id],
    async () => {
      try {
        return await platformGateway.getComponentWorkflowRunInstance(id);
      } catch (e) {
        if ((e as HttpError).httpStatusCode === HttpStatusCode.NotFound)
          return { workflowInstanceUrl: undefined };
        throw e;
      }
    }
  );
};

export const useComponentManagerWorkflowRunInstance = (id: string) => {
  const { platformGateway } = useGateways();
  return useQuery<ComponentWorkflowRunInstanceDto, HttpError>(
    [PltCacheKeys.ComponentManagerWorkflowRunInstance, id],
    async () => {
      try {
        return await platformGateway.getComponentManagerWorkflowRunInstance(id);
      } catch (e) {
        if ((e as HttpError).httpStatusCode === HttpStatusCode.NotFound)
          return { workflowInstanceUrl: undefined };
        throw e;
      }
    }
  );
};

export const useTenantTagQuery = (tenantId: string) => {
  const { platformGateway } = useGateways();
  return useQuery<TenantTag[], AxiosError>(
    [PltCacheKeys.TenantTags, tenantId],
    () => platformGateway.getTenantTags(tenantId)
  );
};

export const useAddTenantTag = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<TenantTag, HttpError, NewTenantTag>(
    platformGateway.addTenantTag,
    {
      onSuccess: async dto => {
        await queryClient.invalidateQueries([
          PltCacheKeys.TenantTags,
          dto.tenantId
        ]);
        await queryClient.invalidateQueries([PltCacheKeys.UsedTenantTags]);
        feedback.success("A new tag has been added successfully!");
      }
    }
  );
};

export const useDeleteTenantTag = () => {
  const { platformGateway } = useGateways();
  const { feedback } = useRootStore();
  const queryClient = useQueryClient();

  return useMutation<TenantTag, HttpError, TenantTag>(
    platformGateway.deleteTenantTag,
    {
      onSuccess: async dto => {
        await queryClient.invalidateQueries([
          PltCacheKeys.TenantTags,
          dto.tenantId
        ]);
        await queryClient.invalidateQueries([PltCacheKeys.UsedTenantTags]);
        feedback.success("A tag has been deleted successfully!");
      }
    }
  );
};

export const useUsedTenantTagsQuery = () => {
  const { platformGateway } = useGateways();
  return useQuery<UsedTenantTag[], AxiosError>(
    [PltCacheKeys.UsedTenantTags],
    () => platformGateway.getUsedTenantTags()
  );
};
